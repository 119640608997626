@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  @include text-body-text--no-xl;
  display: none;
  .slick-active & {
    display: block;
  }
  max-height: 54px;
  overflow: hidden;
  width: 100%;
  margin: 1px 0;
  &__offer-text {
    display: inline;
    P {
      display: inline;
      line-height: inherit;
      margin: 0;
    }
  }
  a {
    @include text-body-text--no-xl;
    @include text-link;
    @include text-link--style-5;
    display: inline-block;
    min-height: 1.5em;
    .header-black-white &,
    .header-black-light-transparent & .header-black-dark-transparent & {
      @include text-link--style-5;
    }
    .header-white-white &,
    .header-white-light-transparent & .header-white-dark-transparent & {
      @include text-link--style-2;
    }
  }
}
